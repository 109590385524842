<template>
    <home/>
</template>

<script>
    import Home from '@/views/Home';

    export default {
        name      : 'TranslatedHome',
        computed  : {
            targetIso: function () {
                return this.$route.meta.iso || 'EN';
            },
        },
        components: {
            Home,
        },
        created   : function () {
            if (!this.$store.getters['Account/getUserProfileLoaded']) {
                this.$i18n.locale = this.targetIso.toLowerCase();
                this.$store.commit('Account/updateInterfaceIso', this.targetIso.toUpperCase());
            } else {
                this.$router.push({name: 'home'});
            }
        },
    };
</script>

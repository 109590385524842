<template>
    <loading-capsule :loaded="pageLoaded" spinner-margin-top="30vh">
        <!--Hero test-->
        <div class="hero"
             style="background-image: url(https://unsplash.com/photos/vmGwPt9gpV0/download?force=true&w=2400)">
            <div class="filmGrain"/>
            <div class="hero__container px-4 sm:px-0 container mx-auto flex items-center justify-center">
                <div class="max-w-base z-0">
                    <div class="min-w-xs text-center mx-auto">
                        <p class="hero__title text-shadow-xl">Style guide</p>
                    </div>
                    <div class="text-center mx-auto">
                        <p class="hero__text text-shadow-lg">
                            Lorem ipsum dolor sit amet, qui libris postea legendos.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!--Typography-->
        <div class="container mx-auto px-4 py-12">
            <div class="text-center pb-2">
                <h1>Text</h1>
                <hr>
            </div>

            <!-- H1 -->
            <div class="heading-container">
                <div class="w-full">
                    <h1>H1 - Lorem ipsum</h1>
                    <p class="no-margin text-xs">Image overlays, titles</p>
                    <p class="font-thin text-xs italic no-margin">
                        font-secondary text-5xl font-normal leading-none tracking-normal uppercase
                    </p>
                </div>
            </div>
            <div class="flex my-4 text-xs font-bold">
                <div class="w-4/12">Font-face</div>
                <div class="w-2/12">Font-size</div>
                <div class="w-3/12">Leading (line-height)</div>
                <div class="w-3/12">Tracking (letter-spacing)</div>
            </div>
            <div class="flex my-4 mb-10 text-xs font-thin">
                <div class="w-4/12">Westmeath</div>
                <div class="w-2/12">3rem</div>
                <div class="w-3/12">1</div>
                <div class="w-3/12">0</div>
            </div>

            <!-- H2 -->
            <div class="heading-container">
                <div class="w-full">
                    <h2>H2 - Lorem ipsum</h2>
                    <p class="no-margin text-xs">Section headings</p>
                    <p class="font-thin text-xs italic no-margin">
                        font-secondary text-4xl font-normal leading-none tracking-normal uppercase
                    </p>
                </div>
            </div>
            <div class="flex my-4 text-xs font-bold">
                <div class="w-4/12">Font-face</div>
                <div class="w-2/12">Font-size</div>
                <div class="w-3/12">Leading (line-height)</div>
                <div class="w-3/12">Tracking (letter-spacing)</div>
            </div>
            <div class="flex my-4 mb-10 text-xs font-thin">
                <div class="w-4/12">Westmeath</div>
                <div class="w-2/12">2.25rem</div>
                <div class="w-3/12">1</div>
                <div class="w-3/12">0</div>
            </div>

            <!-- H3 -->
            <div class="heading-container">
                <div class="w-full">
                    <h3>H3 - Lorem ipsum</h3>
                    <p class="no-margin text-xs">Section sub headings</p>
                    <p class="font-thin text-xs italic no-margin">
                        font-secondary text-3xl font-normal leading-none tracking-normal uppercase
                    </p>
                </div>
            </div>
            <div class="flex my-4 text-xs font-bold">
                <div class="w-4/12">Font-face</div>
                <div class="w-2/12">Font-size</div>
                <div class="w-3/12">Leading (line-height)</div>
                <div class="w-3/12">Tracking (letter-spacing)</div>
            </div>
            <div class="flex my-4 mb-10 text-xs font-thin">
                <div class="w-4/12">Westmeath</div>
                <div class="w-2/12">1.875rem</div>
                <div class="w-3/12">1</div>
                <div class="w-3/12">0</div>
            </div>

            <!-- H4 -->
            <div class="heading-container">
                <div class="w-full">
                    <h4>H4 - Lorem ipsum</h4>
                    <p class="no-margin text-xs">Nav elements</p>
                    <p class="font-thin text-xs italic no-margin">
                        font-primary text-xs font-normal leading-tight tracking-tight uppercase
                    </p>
                </div>
            </div>
            <div class="flex my-4 text-xs font-bold">
                <div class="w-4/12">Font-face</div>
                <div class="w-2/12">Font-size</div>
                <div class="w-3/12">Leading (line-height)</div>
                <div class="w-3/12">Tracking (letter-spacing)</div>
            </div>
            <div class="flex my-4 mb-10 text-xs font-thin">
                <div class="w-4/12">Westmeath</div>
                <div class="w-2/12">.75rem</div>
                <div class="w-3/12">1.25</div>
                <div class="w-3/12">-0.25em</div>
            </div>

            <!-- H5 -->
            <div class="heading-container">
                <div class="w-full">
                    <h5>H5 - Lorem ipsum</h5>
                    <p class="no-margin text-xs">Tiny copy for mobile nav</p>
                    <p class="font-thin text-xs italic no-margin">
                        font-primary text-xxs font-normal leading-tight tracking-tight uppercase
                    </p>
                </div>
            </div>
            <div class="flex my-4 text-xs font-bold">
                <div class="w-4/12">Font-face</div>
                <div class="w-2/12">Font-size</div>
                <div class="w-3/12">Leading (line-height)</div>
                <div class="w-3/12">Tracking (letter-spacing)</div>
            </div>
            <div class="flex my-4 mb-10 text-xs font-thin">
                <div class="w-4/12">Westmeath</div>
                <div class="w-2/12">.6rem</div>
                <div class="w-3/12">1.25</div>
                <div class="w-3/12">-0.25em</div>
            </div>

            <!-- P -->
            <div class="heading-container">
                <div class="w-full">
                    <p>P - Lorem ipsum</p>
                    <p class="no-margin text-xs">Body copy, form labels and placeholders</p>
                    <p class="font-thin text-xs italic no-margin">
                        font-primary text-base font-normal leading-none tracking-normal
                    </p>
                </div>
            </div>
            <div class="flex my-4 text-xs font-bold">
                <div class="w-4/12">Font-face</div>
                <div class="w-2/12">Font-size</div>
                <div class="w-3/12">Leading (line-height)</div>
                <div class="w-3/12">Tracking (letter-spacing)</div>
            </div>
            <div class="flex my-4 mb-10 text-xs font-thin">
                <div class="w-4/12">Westmeath</div>
                <div class="w-2/12">1rem</div>
                <div class="w-3/12">1</div>
                <div class="w-3/12">0</div>
            </div>
        </div>

        <!--Forms-->
        <div class="container mx-auto px-4 py-12">
            <div class="text-center pb-4">
                <h1>Forms</h1>
                <hr>
            </div>
            <!-- Input fields -->
            <div class="w-full">
                <div class="flex flex-wrap">
                    <!-- input -->
                    <div class="input md:w-1/2">
                        <label class="input__label" for="label1">
                            First name
                        </label>
                        <input class="input__field" id="label1" type="text" placeholder="Placeholder">
                    </div>

                    <!-- input -->
                    <div class="input md:w-1/2">
                        <label class="input__label" for="label2">
                            Last name
                        </label>
                        <input
                            class="input__field"
                            id="label2"
                            type="text"
                            placeholder="Placeholder"
                            value="Filled">
                    </div>

                    <!-- input valid -->
                    <div class="input md:w-1/2 input__group--success">
                        <label class="input__label" for="label3">
                            Email
                        </label>
                        <input
                            class="input__field"
                            id="label3"
                            type="text"
                            placeholder="Placeholder"
                            value="Completed">
                    </div>

                    <!-- input invalid error -->
                    <div class="input md:w-1/2" :class="invalidClass">
                        <label class="input__label" for="label4">
                            Invalid input
                        </label>
                        <input
                            class="input__field"
                            id="label4"
                            type="text"
                            placeholder="Placeholder"
                            v-model="inputs.invalid">
                        <p v-show="inputs.invalid.length" class="input__message">Validation message.</p>
                    </div>

                    <!-- input valid -->
                    <div class="input md:w-1/2" :class="validClass">
                        <label class="input__label" for="label5">
                            Valid input
                        </label>
                        <input
                            class="input__field"
                            id="label5"
                            type="text"
                            placeholder="Placeholder"
                            v-model="inputs.valid">
                        <p v-show="inputs.valid.length" class="input__message">Success message.</p>
                    </div>
                </div>

                <div class="flex flex-wrap">
                    <!-- Dropdown -->
                    <div class="input md:w-1/2">
                        <label class="input__label" for="dropdown">
                            What language do you speak?
                        </label>
                        <select class="input__dropdown" id="dropdown">
                            <option
                                :key="item"
                                v-for="item in dropdown"
                                v-text="item"/>
                        </select>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <!-- Radio buttons -->
                    <div class="input md:w-1/2">
                        <label class="input__label">
                            Radio buttons
                        </label>
                        <div class="input__radio">
                            <input
                                class="input__radio-item"
                                id="option1"
                                type="radio"
                                name="radio"
                                value="option1">
                            <label for="option1" class="input__radio-label">Option 1</label>
                        </div>
                        <div class="input__radio">
                            <input
                                class="input__radio-item"
                                id="option2"
                                type="radio"
                                name="radio"
                                checked="checked"
                                value="option1">
                            <label for="option2" class="input__radio-label">Option 2</label>
                        </div>
                        <div class="input__radio">
                            <input
                                class="input__radio-item"
                                id="option3"
                                type="radio"
                                name="radio"
                                value="option3">
                            <label for="option3" class="input__radio-label">Option 3</label>
                        </div>
                    </div>
                    <!-- Checkboxes -->
                    <div class="input md:w-1/2">
                        <label class="input__label">
                            Checkboxes
                        </label>
                        <div class="input__checkbox">
                            <input
                                class="input__checkbox-item"
                                id="check"
                                type="checkbox"
                                name="checkbox"
                                value="true">
                            <label for="check" class="input__checkbox-label">Checkbox unchecked</label>
                        </div>
                        <div class="input__checkbox">
                            <input
                                class="input__checkbox-item"
                                id="uncheck"
                                type="checkbox"
                                checked="checked"
                                name="checkbox">
                            <label for="uncheck" class="input__checkbox-label">Checkbox checked</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Buttons-->
        <div class="container mx-auto px-4 py-12">
            <div class="text-center pb-4">
                <h1>Buttons</h1>
                <hr>
            </div>
            <div class="flex flex-wrap">
                <div class="w-full">
                    <button class="button__btn my-2 button__btn--primary button__btn--fullWidth">
                        Full width button
                    </button>
                </div>
                <div class="w-full">
                    <button class="button__btn my-2 button__btn--secondary button__btn--fullWidth">
                        Secondary full width button
                    </button>
                </div>
                <div class="w-full sm:w-1/2 text-center">
                    <button class="button__btn my-2 button__btn--secondary">
                        Cancel (auto width)
                    </button>
                </div>
                <div class="w-full sm:w-1/2 text-center">
                    <button class="button__btn my-2 button__btn--primary">
                        Proceed (auto width)
                    </button>
                </div>
                <div class="w-full sm:w-1/2 sm:px-2 text-center">
                    <button class="button__btn my-2 button__btn--secondary button__btn--fullWidth">
                        Cancel (full width)
                    </button>
                </div>
                <div class="w-full sm:w-1/2 sm:px-2 text-center">
                    <button class="button__btn my-2 button__btn--primary button__btn--fullWidth">
                        Proceed (full width)
                    </button>
                </div>
            </div>
        </div>

        <!--Modals-->
        <div class="container mx-auto px-4 py-12">
            <div class="text-center pb-2">
                <h1>Modals</h1>
                <hr>
            </div>

            <!-- Normal modal -->
            <div class="my-2 text-center">
                <button
                    class="button__btn button__btn--primary"
                    @click="modals.showA = true">
                    Show Modal
                </button>
                <modal
                    v-if="modals.showA === true"
                    @close="modals.showA = false">
                    <div class="text-center">
                        <p>
                            So, to confirm your subscription for Linquito Premium will start on the 4th of December
                            2020.
                        </p>
                        <p>
                            You will be billed £9.99 monthly starting on the 19th of December 2020.
                        </p>
                        <p>Please confirm you are happy to proceed</p>
                    </div>
                    <template slot="actions-prefix">
                        <button
                            @click.prevent="modals.showA = false"
                            class="shadow-xl button__btn button__btn--secondary mr-2">
                            Confirm
                        </button>
                    </template>
                </modal>
            </div>

            <!-- Modal forbidding close -->
            <div class="my-2 text-center">
                <button
                    class="button__btn button__btn--primary"
                    @click="modals.showB = true">
                    Show Modal (forbidding close)
                </button>
                <modal
                    :allow-cancel="false"
                    :allow-escape="false"
                    :enable-close-button="false"
                    v-if="modals.showB === true"
                    @close="modals.showB = false">
                    <div class="text-center">
                        <h3>Escape</h3>
                        <p>
                            This modal will only allow you to close it by clicking escape below
                        </p>
                    </div>
                    <template slot="actions-prefix">
                        <button
                            @click.prevent="modals.showB = false"
                            class="shadow-xl button__btn button__btn--primary">
                            Escape by clicking here!
                        </button>
                    </template>
                </modal>
            </div>

            <!-- Modal full screen -->
            <div class="my-2 text-center">
                <button
                    class="button__btn button__btn--primary"
                    @click="modals.showC = true">
                    Show Modal (full screen)
                </button>
                <modal
                    :enable-full-screen="true"
                    v-if="modals.showC === true"
                    @close="modals.showC = false">
                    <div class="text-center">
                        <h3>Terms & Conditions</h3>
                        <div class="overflow-y-scroll text-left" style="height: 50vh;">
                            <p v-for="lines in new Array(30)">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi fermentum placerat
                                imperdiet. Sed egestas, sem sit amet fermentum pellentesque, tellus nibh scelerisque
                                tellus, et ultrices metus neque sit amet orci. Aenean ac vulputate risus. Proin
                                porttitor eleifend tempus. Etiam et maximus augue. Nulla sed lobortis sapien.
                            </p>
                        </div>
                    </div>
                </modal>
            </div>
        </div>

        <!--Text slider-->
        <div class="container mx-auto px-4 py-12">
            <div class="text-center pb-2">
                <h1>Text slider</h1>
                <hr>
            </div>
            <div class="sm:flex">
                <div class="sm:w-1/2 px-1 h-10">
                    <h1 class="text-center sm:text-right">
                        <text-fade-slider
                            :texts="['Hey', 'Hallo','Hola', '你好', 'こんにちは', 'Olá', 'Bonjour', 'Ciao']">
                        </text-fade-slider>
                    </h1>
                </div>
                <div class="sm:w-1/2 px-1 h-10 text-center sm:text-left">
                    <h1>
                        Lawrence
                    </h1>
                </div>
            </div>
        </div>
    </loading-capsule>
</template>

<script>
    import Modal from '@/components/structure/Modal';
    import TextFadeSlider from '@/components/structure/TextFadeSlider';
    import LoadingCapsule from '../../components/structure/LoadingCapsule';

    export default {
        name      : 'StyleGuide',
        data      : function () {
            return {
                pageLoaded     : false,
                inputs         : {
                    invalid: 'Wrong',
                    valid  : 'Completed',
                },
                showModal      : false,
                showSecondModal: false,
                dropdown       : [
                    'English',
                    'Spanish',
                    'Chinese',
                    'Japanese',
                    'French',
                ],
                modals         : {
                    showA: false,
                    showB: false,
                    showC: false,
                },
            };
        },
        computed  : {
            invalidClass() {
                return this.inputs.invalid
                    ? 'input--invalid'
                    : '';
            },
            validClass() {
                return this.inputs.valid
                    ? 'input--success'
                    : '';
            },
        },
        mounted   : function () {
            setTimeout(() => {
                this.pageLoaded = true;
            }, 2500);
        },
        components: {
            LoadingCapsule,
            Modal,
            TextFadeSlider,
        },
    };
</script>
